import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const modifyBtns = document.querySelectorAll('.toggleable-form_modify-btn');
    modifyBtns.forEach(btn => btn.addEventListener('click', this.toggleFormDisabled.bind(this)));
  }
  
  toggleFormDisabled(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    if ('Cancel' === event.target.value) {
      event.target.value = 'Modify'
      this.resetFormFields(form);
    } else {
      event.target.value = 'Cancel';
    }

    const updateBtn = form.querySelector('.toggleable-form_update-btn');
    updateBtn.classList.toggle('hidden');

    const formFields = form.querySelectorAll('.toggleable_input')
    formFields.forEach(field => field.toggleAttribute('disabled'));
    const labels = form.querySelectorAll('label');
    labels.forEach(label => label.classList.toggle('enabled'));
  }

  resetFormFields(form) {
    const formFields = form.querySelectorAll('.toggleable_input')
    formFields.forEach(input => input.value = input.defaultValue);
  }
}
