import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="call-calendar"
export default class extends Controller {

  static targets = [ "modal", "modalCloseBtn", "callSlotIdInput", "callSlotBtn", "formField", "submitBtn", "topicSelect" ];
  // this.modalTarget - first matching target
  // this.modalTargets - array of all matching targets
  // this.hasModalTarget - bool indicating if there's a matching target in scope

  connect() {
  }

  modalClose(e) {
    this.modalTarget.style.display = "none";
  }

  callSlotClicked(e) {
    // Set ID of selected Call Slot as value of hidden form field
    const callSlotId = e.target.dataset.callSlot
    this.callSlotIdInputTarget.value = callSlotId; 

    // add a border to the selected btn. 
    this.callSlotBtnTargets.forEach(element => {
      if (callSlotId == element.dataset.callSlot) {
        element.style.border = 'solid 2px #FECE00';
        element.style.color = "#FECE00";
      } else {
        element.style.border = 'solid 2px transparent';
        element.style.color = "#FFF";
      }
    });

    // Enable the form fields
    this.formFieldTargets.forEach(field => field.disabled = false);
  }

  topicSelected(e) {
    if (e.target.value) {
      this.submitBtnTarget.disabled = false;
    } else {
      this.submitBtnTarget.disabled = true;
    }
  }
}
