// Function to save accordion state in local storage
function saveAccordionState(accordionId, isOpen) {
    localStorage.setItem(`accordion_${accordionId}`, isOpen);
}

// Function to retrieve accordion state from local storage
function getAccordionState(accordionId) {
    return localStorage.getItem(`accordion_${accordionId}`);
}

// Function to handle accordion click
function handleAccordionClick(event, accordion) {
    const accordionBody = accordion.querySelector('.ps-accordion_body');
    if (!accordionBody) return;

    const accordionId = accordion.getAttribute('data-accordion-id');
    const isOpen = accordionBody.classList.contains('hidden') ? 'open' : 'closed';

    accordionBody.classList.toggle('hidden');

    const hideRevealBtn = accordion.querySelector('.ps-accordion_icon-btn > i');
    if (!hideRevealBtn) return;

    if (hideRevealBtn.classList.contains('fa-plus')) {
        hideRevealBtn.classList.remove('fa-plus');
        hideRevealBtn.classList.add('fa-x');
    } else {
        hideRevealBtn.classList.remove('fa-x');
        hideRevealBtn.classList.add('fa-plus');
    }

    saveAccordionState(accordionId, isOpen);
}

// Function to initialize accordions
function initializeAccordions() {
    const accordions = document.querySelectorAll('.ps-accordion_container--main');

    if (accordions.length < 1) return;

    accordions.forEach(accordion => {
        const accordionId = accordion.getAttribute('data-accordion-id');
        const accordionState = getAccordionState(accordionId);

        if (accordionState === 'open') {
            const accordionBody = accordion.querySelector('.ps-accordion_body');
            const hideRevealBtn = accordion.querySelector('.ps-accordion_icon-btn > i');

            if (accordionBody && hideRevealBtn) {
                accordionBody.classList.remove('hidden');
                hideRevealBtn.classList.remove('fa-plus');
                hideRevealBtn.classList.add('fa-x');
            }
        } else {
            const accordionBody = accordion.querySelector('.ps-accordion_body');
            const hideRevealBtn = accordion.querySelector('.ps-accordion_icon-btn > i');

            if (accordionBody && hideRevealBtn) {
                accordionBody.classList.add('hidden');
                hideRevealBtn.classList.remove('fa-x');
                hideRevealBtn.classList.add('fa-plus');
            }
        }

        accordion.querySelector('.ps-accordion_header').addEventListener('click', (event) => handleAccordionClick(event, accordion));
    });
}

// Call initializeAccordions function on turbo page reload
document.addEventListener('turbo:load', () => {
    initializeAccordions();
});