import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ 'message', 'continue' ]

    checkValidity(e) {
        const promoCode = e.target.value;

        if ( this.checkEmpty(promoCode) ) return;

        const displayPriceElement = document.querySelector('#call-price')
        const displayPriceValue = displayPriceElement.innerText.replace(/[^\d.-]/g, '');

        const callId = displayPriceElement.dataset.callId;
        const identifier = `call-${callId}`;
        const originalValue = localStorage.getItem(identifier);

        if ( ! originalValue ) this.storeOriginalValue(identifier, displayPriceValue);
        
        fetch('/check-promo-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ code: promoCode, price: (displayPriceValue * 100) })
        })
        .then(response => response.json())
        .then(data => {

            this.messageTarget.innerText = data.message

            if ( data.valid ) {
                this.markAsValid();
                this.updatePrice((data.price / 100), displayPriceElement);
                this.addCodeToQueryParams(promoCode);
            } else {
                this.markAsInvalid();
                if ( originalValue ) this.updatePrice(originalValue, displayPriceElement);
                this.removeCodeFromQueryParams();
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    checkEmpty(promoCode) {
        const promoCodeEmpty = ! promoCode;
        if (promoCodeEmpty) {
            this.messageTarget.innerText = 'Enter a code above';
            this.messageTarget.classList.remove('promo-code-invalid', 'promo-code-valid');
        }
        return promoCodeEmpty;
    }

    storeOriginalValue(id, value) {
        localStorage.setItem(id, value);
        window.addEventListener('beforeunload', function() {
            this.localStorage.removeItem( id );
        });
    }

    markAsValid() {
        this.messageTarget.classList.remove( 'promo-code-invalid' );
        this.messageTarget.classList.add( 'promo-code-valid' );
    }

    markAsInvalid() {
        this.messageTarget.classList.add( 'promo-code-invalid' );
        this.messageTarget.classList.remove( 'promo-code-valid' );
    }

    updatePrice(rawPrice, element) {
        const sanitizedPrice = parseFloat(rawPrice).toFixed(2);
        element.innerText = `€${sanitizedPrice}`;
    }

    addCodeToQueryParams(code) {
        const continueBtn = this.continueTarget;
        const currentUrl = new URL(continueBtn.href);
        currentUrl.searchParams.set("code", code);
        continueBtn.href = currentUrl.toString();
    }

    removeCodeFromQueryParams() {
        const continueBtn = this.continueTarget;
        const currentUrl = new URL(continueBtn.href);
        currentUrl.searchParams.delete("code");
        continueBtn.href = currentUrl.toString();
    }
}