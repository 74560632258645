import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "modalCloseBtn"]

  modalClose(e) {
    this.modalTarget.remove()
  }
  
  modalHide(e) {
    this.modalTarget.style.display = "none";
  }
}
