// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

import "trix"
import "@rails/actiontext"

import "./navbar/navbar";
import "./account/accordion";

// Lazy load active_admin
document.addEventListener("DOMContentLoaded", () => {
    import('./packs/active_admin');
});