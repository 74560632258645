/*
handle navbar menu hide/reveal
*/
(
    function navbarToggler() {
        const navMenuBtns = document.querySelectorAll('.nav_menu_btn');
        navMenuBtns.forEach(btn => btn.addEventListener('click', handleClick));
    }
)();

function handleClick() {
    revealNavMenu();
    toggleNavMenuIcon();
}

function revealNavMenu() {
    const navMenuContainer = document.querySelector("#nav-menu-container")
    const currentVal = navMenuContainer.style.visibility;
    const newVal = currentVal == "visible" ? "hidden" : "visible";
    navMenuContainer.style.visibility = newVal;
};

function toggleNavMenuIcon() {
    const icon = document.querySelector("#navMenuIcon");
    icon.classList.toggle("fa-bars");
    icon.classList.toggle("fa-x");
};
